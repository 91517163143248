<template>
  <b-modal
    id="modal-add-employee"
    size="xl"
    centered
    :title="!data.name ? 'Thêm người lao động' : 'Chỉnh sửa người lao động'"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    @ok="handleOk"
    @hidden="resetData"
  >

    <validation-observer ref="rule">
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group>
            <label>Họ và tên <span class="text-danger">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="Họ và tên"
              rules="required"
              :custom-messages="customName"
            >
              <b-form-input
                v-model="detailUser.name"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group>
            <label>Ngày sinh </label>
            <date-picker
              v-model="detailUser.birthDay"
              :disabledInput="true"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <small>Giới tính</small>
          <b-form-radio-group
            id="radio-group-1"
            v-model="detailUser.gender"
            class="mt-2"
            :options="options"
            name="radio-options"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group>
            <label>Số điện thoại</label>
            <b-form-input
              v-model="detailUser.phoneNumber"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group>
            <label>Địa chỉ</label>
            <b-form-input
              v-model="detailUser.address"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group>
            <label>Ngày đào tạo <span class="text-danger">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="Ngày đào tạo"
              rules="required"
              :custom-messages="customDate"
            >
              <date-picker
                v-model="detailUser.date"
                :disabledInput="true"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
    <div class="mt-2 d-flex">
      <b-form-checkbox
        v-model="detailUser.isInsurance"
        class="mr-2"
      >
        Đã được hưởng trợ cấp thất nghiệp
      </b-form-checkbox>
      <b-form-checkbox
        v-model="detailUser.isAdvise"
        class="mr-2"
      >
        Đã được tư vấn việc làm
      </b-form-checkbox>
      <b-form-checkbox
        v-model="detailUser.isIntroduce"
        class="mr-2"
      >
        Đã được giới thiệu việc làm
      </b-form-checkbox>
      <b-form-checkbox
        v-model="detailUser.isTraining"
      >
        Đã được hỗ trợ đào tạo nghề
      </b-form-checkbox>
    </div>
  </b-modal>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import {
  BRow,
  BCol,
  BFormGroup,
  BModal,
  BFormInput,
  BFormRadioGroup,
  BFormCheckbox,
} from 'bootstrap-vue'
import DatePicker from '@/components/datepicker/DatePicker.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BModal,
    BFormInput,
    DatePicker,
    ValidationProvider,
    ValidationObserver,
    BFormRadioGroup,
    BFormCheckbox,
  },
  props: {
    data: {
      type: Object,
      default() {
        return {
          date: null,
          name: null,
          birthDay: null,
          gender: true,
          address: null,
          phoneNumber: null,
          isInsurance: false,
          isAdvise: false,
          isIntroduce: false,
          isTraining: false,
        }
      },
    },
  },
  data() {
    return {
      detailUser: JSON.parse(JSON.stringify(this.data)),
      options: [
        { text: 'Nam', value: true },
        { text: 'Nữ', value: false },
      ],
      optionsCheckbox: [
        { text: 'Tham gia các khóa đào tạo kĩ năng', value: 1 },
        { text: 'Tham gia các khóa đào tạo nghề nghiệp', value: 2 },
        { text: 'Tìm được việc làm', value: 3 },
      ],
      customName: {
        required: 'Họ và tên là bắt buộc',
      },
      customDate: {
        required: 'Ngày đào tạo là bắt buộc',
      },
    }
  },
  watch: {
    data: {
      deep: true,
      handler(val) {
        this.detailUser = JSON.parse(JSON.stringify(val))
      },
    },
  },
  methods: {
    resetData() {
      this.detailUser = JSON.parse(JSON.stringify(this.data))
    },
    handleOk(e) {
      e.preventDefault()
      this.$refs.rule.validate().then(async success => {
        if (success) {
          this.$bvModal.hide('modal-add-employee')
          this.$emit('update', this.detailUser)
        }
      })
    },
  },
}
</script>
